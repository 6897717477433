<template>
<Navbar :user="user" />
  <section class="about-area ptb-100"  v-if="!isLoading">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="text-start">
                <h5 class="text-center">Questions:</h5>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end pb-4">
                    <router-link :to="{ name: 'TrainerTestQuestionsEdit', params: {id: question.id}}" class="btn default-btn">Edit Question</router-link>
                </div>
                <div>
                    <h6>Title: {{ question.title }}</h6>
                    <p>Description: {{ question.description }}</p>
                    <p>Duration: {{ question.question_duration }} Sec</p>
                </div>
                <h5 class="text-center">Answers:</h5>
                <p v-for="(option , index) in question.test_question_options" :key="option.id">
                    <span v-if="question.correct_answer == option.sort" class="alert alert-success pt-1 pb-2">
                        option 
                        <span v-if="index + 1 == 1">One</span>
                        <span v-if="index + 1 == 2">Two</span>
                        <span v-if="index + 1 == 3">Three</span>
                        <span v-if="index + 1 == 4">Four</span>: {{ option.title }} 
                        <span class="badge text-default rounded-pill">
                            <router-link class="text-default" :to="{ name: 'TrainerTestOptionEdit', params: {id: option.id}, params: {question: option.id}}">
                                <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                            </router-link>    
                        </span>
                    </span>
                    <span v-else class="alert alert-light pt-2">
                        option 
                        <span v-if="index + 1 == 1">One</span>
                        <span v-if="index + 1 == 2">Two</span>
                        <span v-if="index + 1 == 3">Three</span>
                        <span v-if="index + 1 == 4">Four</span>: {{ option.title }} 
                        <span class="badge rounded-pill">
                            <router-link class="text-default" :to="{ name: 'TrainerTestOptionEdit', params: {id: option.id}, params: {question: option.id}}">
                                <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                            </router-link>
                        </span>
                    </span>
                    
                </p>
                <div class="text-center">
                    <router-link :to="{ name: 'TrainerDetailsTest', params: {id: question.test_id}}" class="btn default-btn">Back To Test</router-link>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>
  <div v-else>
    <Loading />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Loading from '@/components/Loading.vue'
export default {
  components: { Navbar,Loading },
       data(){
        return{
            isLoading: true,
            values: [],
            question: {},
            textFields: [],
            user: {},
            token: localStorage.getItem('user_data'),
            errors: {}
            
        }
    },
    methods: {
    getQuestion(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/test_question/profile/' + id).then(response => {
        this.question = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Create Question - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser() 
    this.getQuestion()
  }

}
</script>

<style>

</style>